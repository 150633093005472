import styled from 'styled-components';

export const Container = styled.section`
  display: inline-block;
  width: 100%;
  text-align: center;
`;

export const Team = styled.div`
  > div {
    display: inline-block;
    padding: 1rem;
  }
`;
